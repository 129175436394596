import { useI18n } from "vue-i18n";
import algoliasearch from "algoliasearch";

const client = algoliasearch(
  process.env.VUE_APP_ALGOLIA_APP_ID,
  process.env.VUE_APP_ALGOLIA_API_KEY
);

export function useAlgolia() {
  const { locale } = useI18n();
  let indexName = "Style Guide English";

  if(locale.value === "es") {
    indexName = "Style Guide Spanish";
  } else if(locale.value === "fr") {
    indexName = "Style Guide French";
  }
    
  const index = client.initIndex(indexName);

  const indexByPublishedName =
    locale.value === "en"
      ? "sg_en_original_published_desc"
      : "sg_es_original_published_desc";
  const indexByPublished = client.initIndex(indexByPublishedName);
  return { index, indexByPublished };
}
