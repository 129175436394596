import { defineStore } from "pinia";

//* using option store syntax
export const useAppStore = defineStore("AppStore", {
  state: () => ({
    language: "en"
  }),
  // update language
  actions: {
    updateLanguage(language) {
      this.language = language;
    }
  },
  // get language
  getters: {
    getLanguage(state) {
      return state.language;
    }
  },
  persist: true
});
