export const strings = {
  en: {
    global: "Global",
    mainLayout: {
      header: "Global Press Style Guide",
      intro:
        "Promoting dignity and precision in the practice of international journalism",
      artby: "Artwork by: "
    },
    searchUI: {
      english: "English",
      spanish: "Spanish",
      french: "French",
      sortByLabel: "Sort By",
      recentlyUpdated: "Recently Updated",
      recentlyAdded: "Recently Added",
      filterBy: "Filter by",
      topics: "Topics",
      locations: "Locations",
      rationales: "Rationales",
      dignity: "Dignity",
      precision: "Precision",
      dignityAndPrecision: "Dignity and Precision",
      all: "All",
      datePublished: "Published",
      dateUpdated: "Updated",
      searchForEntry: "Search for an entry",
      searchForEntryPlaceholder: "Search for an entry",
      entriesbyTopic: "Entries by Topic",
      entriesbyLocation: "Entries by Location",
      entriesbyRationale: "Entries by Rationale",
      allStyles: "All Terms",
      submit: "Submit",
      tendingEntries: "Tending Entries",
      recentEntries: "Recent Entries",
      aboutThisGuide: "About This Guide",
      bans: "Bans",
      showOutrightBans: "Show outright bans",
      location: "Location",
      relatedTopics: "Related Topics",
      relatedStyles: "Related Terms",
      note: "Note",
      xStylesFound: "Showing",
      xStylesFound2: "Terms",
      showingPage: "Showing page",
      showingPageOf: "of",
      noResults: "No terms found",
      namesAZ: "Names A-Z",
      namesZA: "Names Z-A",
      newestStyles: "Newest Terms",
      oldestStyles: "Oldest Terms",
      recentlyUpdatedStyles: "Recently Updated Terms",
      seeAlso: "See Also",
      rule: "Rule",
      rationale: "Rationale",
      outrightBan: "Outright Ban",
      precision: "Precision",
      dignity: "Dignity",
      dignityAndPrecision: "Dignity and Precision",
      starttyping: "Start typing to search for a term",
      allStyles: "All Terms",
      askGlobalPress: "Ask Global Press",
      submitQuestion: "Submit Question",
      previoustlyAnswered: "Previously Answered",
      notAnswered: "This question has not been answered",
      noQuestions: "There are currently no questions for this term",
      noAnswers: "No answers found",
      seeAllQuestionsAndAnswers: "See all questions and answers",
      questionSubmitted: "Question submitted",
      questionSubmitError: "Fill in question",
      latestQuestions: "Latest Questions",
      noStyles: "No terms found",
      trendingStyles: "Trending Terms",
      loading: "Loading"
    }
  },
  fr: {
    global: "Mondial",
    mainLayout: {
      header: "Guide de style de Global Press",
      intro:
        "Promouvoir la dignité et la précision dans la pratique du journalisme international",
      artby: "Illustration par :"
    },
    searchUI: {
      english: "Anglais",
      spanish: "Espagnol",
      french: "Français",
      sortByLabel: "Trier par",
      recentlyUpdated: "Récemment mises à jour",
      recentlyAdded: "Récemment ajoutées",
      filterBy: "Filtrer par",
      topics: "Sujets ",
      locations: "Lieux",
      rationales: "Justifications",
      dignity: "Dignité",
      precision: "Précision",
      dignityAndPrecision: "Dignité et précision",
      all: "Toutes ",
      datePublished: "Publication",
      dateUpdated: "Mise à jour",
      searchForEntry: "Rechercher une entrée",
      searchForEntryPlaceholder: "Rechercher une entrée",
      entriesbyTopic: "Entrées par sujet",
      entriesbyLocation: "Entrées par lieu",
      entriesbyRationale: "Entrées par justification",
      allStyles: "Tous les termes",
      submit: "Envoyer",
      tendingEntries: "Entrées fréquemment visitées",
      recentEntries: "Entrées récentes",
      aboutThisGuide: "À propos de ce Guide",
      bans: "Interdictions",
      showOutrightBans: "Afficher les interdictions totales",
      location: "Lieu",
      relatedTopics: "Sujets connexes",
      relatedStyles: "Termes connexes",
      note: "Remarque",
      xStylesFound: "Afficher",
      xStylesFound2: "Termes",
      showingPage: "Afficher la page",
      showingPageOf: "de",
      noResults: "Aucun terme trouvé",
      namesAZ: "Noms A-Z",
      namesZA: "Noms Z-A",
      newestStyles: "Termes les plus récents",
      oldestStyles: "Termes les plus anciens",
      recentlyUpdatedStyles: "Termes récemment mis à jour",
      seeAlso: "Voir aussi",
      rule: "Règle",
      rationale: "Justification",
      outrightBan: "Interdiction totale",
      starttyping: "Commencer à taper pour rechercher un terme",
      askGlobalPress: "Question à Global Press",
      submitQuestion: "Envoi de question",
      previoustlyAnswered: "Réponse déjà donnée",
      notAnswered: "Pas encore de réponse à cette question",
      noQuestions: "Pour le moment, pas de questions pour ce terme",
      noAnswers: "Aucune réponse trouvée",
      seeAllQuestionsAndAnswers: "Voir toutes les questions et réponses",
      questionSubmitted: "Question envoyée",
      questionSubmitError: "Remplir la question",
      latestQuestions: "Questions récentes",
      noStyles: "Aucun terme trouvé",
      trendingStyles: "Termes fréquemment visités  ",
      loading: "Chargement"
    }
  },
  es: {
    global: "Global",
    mainLayout: {
      header: "Guía de estilo de Global Press",
      intro:
        "Fomentando la dignidad y la precisión en la práctica del periodismo internacional",
      artby: "Artista: "
    },
    searchUI: {
      english: "Inglés",
      spanish: "Español",
      french: "French",
      sortByLabel: "Clasificar por",
      recentlyAdded: "Adiciones recientes",
      recentlyUpdated: "Actualizaciones recientes",
      filterBy: "Filtrar por",
      topics: "Temas",
      locations: "Países/Regiones",
      rationales: "Justificaciones",
      dignity: "Dignidad",
      precision: "Precisión",
      dignityAndPrecision: "Dignidad y precisión",
      all: "Todas",
      datePublished: "Fecha de publicación",
      dateUpdated: "Fecha de actualización",
      searchForEntry: "Buscar regla",
      searchForEntryPlaceholder: "",
      entriesbyTopic: "Reglas por tema",
      entriesbyLocation: "Reglas por región",
      entriesbyRationale: "",
      allStyles: "Todas las reglas",
      submit: "Enviar",
      tendingEntries: "Reglas populares",
      recentEntries: "Reglas recientes",
      aboutThisGuide: "Acerca de la guía",
      bans: "",
      showOutrightBans: "Mostrar prohibiciones",
      location: "Países",
      relatedTopics: "Temas relacionados",
      note: "Nota",
      relatedStyles: "Reglas relacionadas",
      xStylesFound: "Se encuentran",
      xStylesFound2: "reglas",
      showingPage: "Mostrando página",
      showingPageOf: "de",
      noResults: "No se encuentran reglas",
      namesAZ: "Nombres A - Z",
      namesZA: "Nombres Z - A",
      newestStyles: "Reglas más recientes",
      oldestStyles: "Reglas menos recientes",
      recentlyUpdatedStyles: "Reglas actualizadas recientemente",
      seeAlso: "Ver también",
      rule: "Regla",
      rationale: "Justificación",
      outrightBan: "Prohibido",
      starttyping: "Comience a escribir para buscar una regla",
      allStyles: "Todas las reglas",
      askGlobalPress: "Pregúntele a Global Press",
      submitQuestion: "Enviar pregunta",
      previoustlyAnswered: "Previamente contestada",
      notAnswered: "Esta pregunta no ha sido contestada",
      noQuestions: "Actualmente no hay preguntas relacionadas con esta regla",
      noAnswers: "",
      seeAllQuestionsAndAnswers: "Ver todas las preguntas y respuestas",
      questionSubmitted: "Pregunta enviada con éxito",
      questionSubmitError: "Rellene la pregunta",
      latestQuestions: "Preguntas más recientes",
      noStyles: "No se encuentran reglas",
      trendingStyles: "Reglas populares",
      loading: "Cargando"
    }
  }
};
