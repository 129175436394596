<template>
    <header class="sg-border-b sg-relative sg-py-6 sg-navbar sg-border-base sg-bg-white">

        <div class="sg-mx-auto sg-container-xl">

            <nav class="sg-flex sg-space-between">
                <div class="sg-flex sg-pt-4 sg-nav-side">
                    <div class="relative">
                        <div class="sg-mr-5 sg-self-start sg-hamburger-wrap" @click="menuOpen = !menuOpen"
                            :class="{ 'sg-menu-open': menuOpen }">
                            <button class="sg-hamburger-button" aria-haspopup="true"
                                aria-controls="main-header-drop-down-nav"
                                :class="{ 'sg-menu-open': menuOpen }">Menu</button>
                        </div>
                        <div class="sg-navbar-dropdown-nav" v-if="menuOpen">
                            <ul id="menu-main-menu" class="">
                                <li v-for="item in mainNav" :key="item.id"
                                    class="sg-font-medium menu-item menu-item-type-custom menu-item-object-custom"
                                    :class="{ 'menu-item-has-children has-dropdown': item.children && item.children.length }">
                                    <a :href="item.url">{{ item.title }}</a>
                                    <ul v-if="item.children && item.children.length">
                                        <li v-for="child in item.children" :key="child.id"
                                            class="menu-item menu-item-type-taxonomy menu-item-object-category">
                                            <a :href="child.url">{{ child.title }}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div class="sg-hidden lg:sg-flex">
                        <a href="https://www.globalpressimpact.com/donations/donate-1"
                            class="sg-mr-6 sg-text-sm sg-text-gray-800 sg-uppercase">Donate</a>
                        <a href="https://styleguide.globalpressjournal.com"
                            class="sg-text-sm sg-text-gray-800 sg-uppercase">Style Guide</a>
                    </div>
                </div>

                <div class="sg-logo">
                    <a href="https://www.globalpressjournal.com"><img src="/images/gpj-logo.png" alt=""
                            title="Global Press"></a>
                    <p class="sg-mt-2 sg-text-sm sg-text-center sg-text-gray-500 lg:sg-text-xl sg-mb-0">Know your world.
                    </p>
                </div>
                <div class="sg-pt-4 sg-nav-side">
                    <div class="sg-items-center sg-justify-end sg-hidden sg-space-x-2 lg:sg-flex">


                        <a href="https://www.facebook.com/globalpressjournal/" title="Visit our Facebook"><img
                                class="sg-w-[32px]" src="/images/facebook.png" alt="facebook"></a>
                        <a href="https://twitter.com/GlobalPress" title="Visit our Twitter"><img class="sg-w-[32px]"
                                src="/images/twitter.png" alt="twitter"></a>
                    </div>
                </div>
            </nav>

        </div>

    </header>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios'

const searchOpen = ref(false);
const menuOpen = ref(false);

const mainNav = ref([]);

const getMainMenu = async () => {
    const res = await axios({
        method: 'get',
        url: 'https://www.globalpressjournal.com/wp-json/menus/v1/menus/15819?nested=1&fields=url,ID,title,meta',
    })
    return res.data;
}

onMounted(async () => {
    mainNav.value = await getMainMenu();
})

</script>

<style lang="scss" scoped>
</style>