import { createRouter, createWebHistory } from "vue-router";
import Notfound404Page from "@/components/pages/404.vue";
import Home from "@/components/pages/HomePage.vue";
import QAArchive from "@/components/pages/QAArchivePage.vue";
import Single from "@/components/pages/SinglePage.vue";

const routes = [
  {
    path: "/:lang?",
    name: "Spanish Home",
    component: Home
  },

  {
    path: "/:lang?/style/:slug",
    component: Single,
    name: "style-details"
  },
  {
    path: "/qa-archives",
    name: "QA Archive",
    component: QAArchive,
    meta: {
      layout: "AppLayoutMain"
    }
  },
  { path: "/:url(.*)", component: Notfound404Page }
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  },
  routes // short for `routes: routes`
});
export default router;
