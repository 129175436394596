<template>
    <router-link :to="{ name: 'style-details', params: { slug: item.slug, lang: locale } }" v-for="item in styles"
        :key="item.id" class="hover:sg-bg-gray-50 sg-px-2 sg-block sg-text-sm sg-py-3 sg-border-b">
        {{ item.name }}
    </router-link>
</template>

<script setup>
import { ref } from 'vue'
import { useAlgolia } from "@/composables/useAlgolia.js";
import { useI18n } from 'vue-i18n'
const { indexByPublished } = useAlgolia();
const styles = ref([]);
const { locale } = useI18n()

indexByPublished.search('', { hitsPerPage: 10, }).then(({ hits }) => {
    styles.value = hits;
});

</script>

<style lang="scss" scoped>
</style>