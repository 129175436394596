export const useAisClasses = () => {
  const searchBoxClasses = {
    "ais-SearchBox": "",
    "ais-SearchBox-form": "",
    "ais-SearchBox-input":
      "sg-pr-32 sg-w-full sg-h-[74px] sg-border sg-border-gray-300 sg-px-2 sg-pl-14 sg-text-lg sg-rounded-none sg-relative sg-z-10 sg-bg-white",
    "ais-SearchBox-submit": "",
    "ais-SearchBox-submitIcon": "",
    "ais-SearchBox-reset":
      "sg-w-[24px] sg-h-[24px] sg-bg-gray-100 sg-rounded-full sg-p-1 sg-z-20 sg-mr-16 sg-p-2",
    "ais-SearchBox-resetIcon": "sg-w-[10px] sg-h-[10px]",
    "ais-SearchBox-loadingIndicator": "",
    "ais-SearchBox-loadingIcon": ""
  };
  const menuClasses = {
    "ais-Menu": "",
    "ais-Menu--noRefinement": "",
    "ais-Menu-list": "space-y-2",
    "ais-Menu-item": "",
    "ais-Menu-item--selected": "",
    "ais-Menu-link": "flex justify-between",
    "ais-Menu-label": "",
    "ais-Menu-count":
      "text-gray-600 bg-gray-100 text-xs font-medium w-4 h-4 flex items-center justify-center",
    "ais-Menu-showMore": "",
    "ais-Menu-showMore--disabled": ""
  };
  const voiseSearchClasses = {
    "ais-VoiceSearch":
      "sg-bg-gray-100 sg-border hover:sg-bg-gray-50  sg-border-base",
    "ais-VoiceSearch-button": "",
    "ais-VoiceSearch-status": ""
  };
  const aisRefinementListClasses = {
    "ais-RefinementList-count":
      "sg-ml-auto sg-text-[9px] sg-border sg-border-gray-200 sg-p1 sg-rounded-full sg-text-gray-600 sg-w-5 sg-h-5 sg-flex sg-items-center sg-justify-center sg-text-center sg-bg-white sg-leading-none",
    "ais-RefinementList-label": "sg-mb-2 sg-flex",
    "ais-RefinementList-labelText": "sg-text-sm",
    "ais-RefinementList-checkbox":
      "sg-mr-2 sg-w-5 sg-h-5 sg-text-primary-600 sg-bg-gray-50 sg-border-gray-200 focus:sg-ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:sg-ring-2 dark:bg-gray-700 dark:border-gray-600"
  };
  return {
    searchBoxClasses,
    menuClasses,
    voiseSearchClasses,
    aisRefinementListClasses
  };
};
