<template>
    <PageStructure>
        <div v-if="loading === true"
            class="sg-w-full sg-h-full sg-fixed sg-top-0 sg-left-0 sg-bg-white sg-flex sg-items-center sg-justify-center sg-z-50">
            <GPLoader />
        </div>
        <div :hidden="loading">
            <ais-instant-search :click-analytics.camel="true" :search-function="searchFunction"
                :initial-ui-state="initialUiState" :middlewares="middlewares" :search-client="searchClient"
                :index-name="languageSettings.indexName">
                <ais-configure :click-analytics.camel="true" :hits-per-page.camel="200"
                    :attributesToHighlight="['name', 'rule', 'rationale']" />
                <div class="lg:sg-grid sg-grid-cols-12 sg-gap-10">
                    <div class="sg-sticky sg-col-span-12 -sg-mt-[40px] sg-mb-8 sg-top-0 sg-z-30">
                        <div v-if="locale === 'en'" class="sg-absolute sg-right-3 sg-z-20 sg-top-3">
                            <ais-voice-search search-as-you-speak button-title="Voice Search"
                                :class-names="voiseSearchClasses" />
                        </div>

                        <div v-if="locale === 'es'" class="sg-absolute sg-right-3 sg-z-20 sg-top-3">
                            <ais-voice-search language="es-ES" search-as-you-speak button-title="Voice Search"
                                :class-names="voiseSearchClasses" />
                        </div>
                        
                        <div v-if="locale === 'fr'" class="sg-absolute sg-right-3 sg-z-20 sg-top-3">
                            <ais-voice-search language="fr-FR" search-as-you-speak button-title="Voice Search"
                                :class-names="voiseSearchClasses" />
                        </div>


                        <div class="sg-relative">
                            <ais-search-box :class-names="searchBoxClasses" :placeholder="$t('searchUI.starttyping')" />
                            <div class="sg-absolute sg-left-4 sg-top-1/2 -sg-translate-y-1/2 sg-z-20">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                    class="sg-w-[24px] sg-text-gray-700">
                                    <path fill="currentColor"
                                        d="m22.241 24-7.414-7.414c-1.559 1.169-3.523 1.875-5.652 1.885h-.002c-.032 0-.07.001-.108.001-5.006 0-9.065-4.058-9.065-9.065 0-.038 0-.076.001-.114v.006c0-5.135 4.163-9.298 9.298-9.298s9.298 4.163 9.298 9.298c-.031 2.129-.733 4.088-1.904 5.682l.019-.027 7.414 7.414zm-12.942-21.487c-3.72.016-6.73 3.035-6.73 6.758 0 3.732 3.025 6.758 6.758 6.758s6.758-3.025 6.758-6.758c0-1.866-.756-3.555-1.979-4.778-1.223-1.223-2.912-1.979-4.778-1.979-.01 0-.02 0-.03 0h.002z" />
                                </svg>
                            </div>

                            <ais-stats
                                class="sg-text-xs sg-text-gray-500 sg-mr-2 sg-absolute sg-z-20 sg-right-[100px] sg-top-1/2 -sg-translate-y-1/2 sg-hidden md:sg-block">
                                <template v-slot="{ nbPages, nbHits, page }">
                                    ({{ $t("searchUI.xStylesFound") }} <b>{{ nbHits }}</b> {{
                                            $t("searchUI.showingPageOf")
                                    }} <b> {{
        totalItems
}}</b> {{
        $t("searchUI.xStylesFound2")
}})
                                </template>
                            </ais-stats>

                        </div>
                        <div v-if="hasRefinements"
                            class="sg-p-2 sg-flex sg-items-center sg-space-x-2 sg-border sg-border-gray-300 sg-relative sg-z-20 -sg-mt-1 sg-bg-gray-50">
                            <ais-current-refinements :transform-items="transformRefinements" />
                            <ais-clear-refinements>
                                <template v-slot="{ canRefine, refine, createURL }">
                                    <a :href="createURL()" @click.prevent="refine" v-if="canRefine"
                                        class="sg-clear-all-refinements">
                                        Clear all
                                    </a>
                                </template>
                            </ais-clear-refinements>
                        </div>
                        <div
                            class="sg-shadow-xl sg-w-[98%] sg-opacity-50 sg-h-full sg-absolute sg-bottom-0 sg-left-1/2 -sg-translate-x-1/2 sg-mx-auto sg-z-0">
                        </div>
                    </div>
                    <div class="sg-col-span-3">
                        <div class="sg-sidebar-items sg-pb-10 sg-sticky sg-top-36 ">
                            <ScrollPanel style="width: 100%; height: calc(100vh - 160px)">

                                <ais-stats class="sg-text-xs sg-text-gray-500 sg-mr-2 sg-mb-2 sg-block md:sg-hidden">
                                    <template v-slot="{ nbPages, nbHits, page }">
                                        ({{ $t("searchUI.xStylesFound") }} <b>{{ nbHits }}</b> {{
                                                $t("searchUI.showingPageOf")
                                        }} <b> {{
        totalItems
}}</b> {{
        $t("searchUI.xStylesFound2")
}})
                                    </template>
                                </ais-stats>

                                <div class="sg-sidebar-item sg-mb-10 ">
                                    <!-- <h2 class="sg-text-2xl sg-font-bold sg-mb-2 sg-pb-2">{{ $t("searchUI.sortByLabel") }}
                            </h2> -->
                                    <ais-sort-by
                                        :class-names="{ 'ais-SortBy-select': 'sg-w-full sg-bg-white sg-border-base-light' }"
                                        :items="[
                                            { value: languageSettings.indexName, label: $t('searchUI.sortByLabel') },
                                            { value: languageSettings.indexName, label: $t('searchUI.namesAZ') },
                                            { value: `sg_${locale}_name_desc`, label: $t('searchUI.namesZA') },
                                        
                                            { value: `sg_${locale}_original_published_desc`, label: $t('searchUI.newestStyles') },
                                            { value: `sg_${locale}_original_published_asc`, label: $t('searchUI.oldestStyles') },
                                            { value: `sg_${locale}_sort_updated_asc`, label: $t('searchUI.recentlyUpdatedStyles') },
                                        ]" />
                                </div>
                                <div class="sg-sidebar-item sg-space-y-10">

                                    <Disclosure v-slot="{ open }" defaultOpen as="div"
                                        class="sg-sidebar-item sg-bg-white sg-border sg-p-6 sg-border-base-light">
                                        <DisclosureButton
                                            class="sg-w-full sg-text-left sg-flex sg-text-lg sg-justify-between sg-font-medium">
                                            {{ $t("searchUI.topics") }}
                                            <span v-if="open">-</span>
                                            <span v-else>+</span>
                                        </DisclosureButton>
                                        <DisclosurePanel class="sg-pt-6">
                                            <ais-panel>
                                                <ais-refinement-list :class-names="aisRefinementListClasses"
                                                    class="border-1 rounded border-slate-600 bg-current text-green-400 outline-2 outline-offset-4 focus:outline focus:outline-white focus:ring-offset-purple-500"
                                                    attribute="topics" :sort-by="['name:asc']" :limit="30" />
                                            </ais-panel>
                                        </DisclosurePanel>
                                    </Disclosure>
                                    <Disclosure v-slot="{ open }" defaultOpen as="div"
                                        class="sg-sidebar-item sg-bg-white sg-border sg-p-6 sg-border-base-light">
                                        <DisclosureButton
                                            class="sg-w-full sg-text-left sg-flex sg-text-lg sg-justify-between sg-font-medium">
                                            {{ $t("searchUI.locations") }}
                                            <span v-if="open">-</span>
                                            <span v-else>+</span>
                                        </DisclosureButton>
                                        <DisclosurePanel class="sg-pt-6">
                                            <ais-panel>
                                                <ais-refinement-list :class-names="aisRefinementListClasses"
                                                    attribute="country" :sort-by="['count:desc']" :limit="30" />
                                            </ais-panel>
                                        </DisclosurePanel>
                                    </Disclosure>
                                    <Disclosure v-slot="{ open }" defaultOpen as="div"
                                        class="sg-sidebar-item sg-bg-white sg-border sg-p-6 sg-border-base-light">
                                        <DisclosureButton
                                            class="sg-w-full sg-text-left sg-flex sg-text-lg sg-justify-between sg-font-medium">
                                            {{ $t("searchUI.rationale") }}
                                            <span v-if="open">-</span>
                                            <span v-else>+</span>
                                        </DisclosureButton>
                                        <DisclosurePanel class="sg-pt-6">
                                            <ais-panel>
                                                <ais-refinement-list class="sg-capitalize"
                                                    :transform-items="transformRationales"
                                                    :class-names="aisRefinementListClasses"
                                                    attribute="rationales_category" />
                                            </ais-panel>
                                        </DisclosurePanel>
                                    </Disclosure>
                                    <!-- <Disclosure v-slot="{ open }" defaultOpen as="div" v-if="locale === 'en'"
                                        class="sg-sidebar-item sg-bg-white sg-border sg-p-6 sg-border-base-light">
                                        <DisclosureButton
                                            class="sg-w-full sg-text-left sg-flex sg-text-lg sg-justify-between sg-font-medium">
                                            AP Deviations
                                            <span v-if="open">-</span>
                                            <span v-else>+</span>
                                        </DisclosureButton>
                                        <DisclosurePanel class="sg-pt-6">

                                            <ais-menu attribute="deviation" :sort-by="['name:asc']">
                                                <template v-slot="{
                                                    items,
                                                    refine,
                                                    createURL,
                                                    sendEvent,
                                                }">
                                                    <div class="sg-inline-flex sg-flex-wrap">
                                                        <button type="button"
                                                            @click.prevent="refine(item.value); createURL(item.value)"
                                                            v-for="(item, i) in items.filter(i => i.value !== 'none')"
                                                            :key="item.value" :class="[
                                                            
                                                                { '-sg-mx-1 ': i === 1 },
                                                                { 'sg-bg-primary-500 sg-border sg-border-primary-700 sg-text-white': item.isRefined },
                                                                { 'sg-bg-white sg-border sg-border-base-light sg-text-gray-800': !item.isRefined }
                                                            ]"
                                                            class="sg-relative hover:sg-z-10 sg-py-2 sg-px-3 sg-text-xs sg-font-medium sg-bg-white hover:sg-bg-primary-500 hover:sg-border-primary-700 hover:sg-text-white focus:sg-z-10 ">
                                                            <span v-if="item.value === 'deviation'">Deviations</span>
                                                            <span v-if="item.value === 'previous_deviation'">Was
                                                                Deviation</span>
                                                        </button>
                                                    </div>
                                                </template>
                                            </ais-menu>
                                        </DisclosurePanel>
                                    </Disclosure> -->
                                </div>

                            </ScrollPanel>
                        </div>
                    </div>
                    <div class="sg-space-y-10 sg-col-span-9">
                        <ais-hits class="mt-0">
                            <template v-slot="{ items, sendEvent }">
                                <div v-if="items.length" class="sg-space-y-10">
                                    <div v-for="style in items" :key="style.objectID"
                                        @click="sendEvent('click', style, 'Style Opened'); searchTerm = '';"
                                        class="sg-bg-white sg-transition-shadow hover:sg-shadow-lg hover:sg-shadow-gray-100/90  sg-border sg-p-8 sg-block sg-border-base-light">
                                        <article class="sg-space-y-3">
                                            <router-link
                                                :to="{ name: 'style-details', params: { slug: style.slug, lang: locale } }">
                                                <h2
                                                    class="sg-text-3xl sg-font-semibold sg-flex sg-items-center sg-justify-between">
                                                    {{ style.name }}
                                                    <!-- <ais-highlight :hit="style" attribute="rationale" /> -->
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                        class="sg-w-10" fill="currentColor">
                                                        <path
                                                            d="M12.15 11.847a.5.5 0 01.71-.705 3.34 3.34 0 01-.004 4.713l-2.168 2.173a3.33 3.33 0 01-4.694.02 3.312 3.312 0 01-.034-4.684l2.184-2.215a.5.5 0 11.712.702l-2.183 2.215a2.312 2.312 0 00.023 3.269c.913.9 2.38.893 3.284-.014l2.168-2.172a2.34 2.34 0 00.003-3.302zm-.462.306a.5.5 0 11-.71.705 3.343 3.343 0 01.002-4.715l2.088-2.1a3.506 3.506 0 015.042.076 3.283 3.283 0 01-.166 4.64l-2.265 2.107a.5.5 0 11-.68-.732l2.263-2.107a2.283 2.283 0 00.024-3.32 2.506 2.506 0 00-3.509.042l-2.089 2.099c-.91.914-.91 2.39 0 3.305z" />
                                                    </svg>
                                                </h2>
                                            </router-link>

                                            <p v-if="style.deviation === 'deviation'"
                                                class="sg-text-sm sg-text-gray-600 sg-font-medium sg-italic">
                                                This is a deviation from AP Style.
                                            </p>

                                            <p v-if="style.deviation === 'previous_deviation'"
                                                class="sg-text-sm sg-text-gray-600 sg-font-medium sg-italic">
                                                This is no longer a deviation from AP Style.
                                            </p>
                                            <div>
                                                <b>{{ $t("searchUI.rule") }}<span v-if="locale === 'fr'">&nbsp;</span>: </b>
                                                <span v-html="style.rule" class=" sg-whitespace-pre-line"></span>
                                            </div>
                                            <div>
                                                <b>{{ $t("searchUI.rationale") }}<span v-if="locale === 'fr'">&nbsp;</span>: </b>
                                                <ais-highlight :hit="style" attribute="rationale" />
                                            </div>
                                            <div v-if="style.related_styles.length">
                                                <b>{{ $t("searchUI.seeAlso") }}<span v-if="locale === 'fr'">&nbsp;</span>: </b>
                                                <router-link v-for="(relStyle, index) in style.related_styles"
                                                    :key="index"
                                                    :to="{ name: 'style-details', params: { slug: relStyle.slug, lang: locale } }"
                                                    class=" sg-text-primary-500 hover:sg-underline">{{
                                                            relStyle.name
                                                    }}<span v-if="index !== style.related_styles.length - 1"><span v-if="locale === 'fr'">&nbsp;</span>; </span>
                                                </router-link>

                                            </div>
                                            <div v-if="style.topics">
                                                <b>{{ $t("searchUI.relatedTopics") }}<span v-if="locale === 'fr'">&nbsp;</span>: </b>
                                                <a :href="`/?topic=${topic}`" v-for="(topic, index) in style.topics" :key="index"
                                class=" sg-inline sg-text-primary-500 hover:sg-underline">
                                {{
                                        topic
                                }}<span v-if="index !== style.topics.length - 1">, </span>
                            </a>

                                            </div>
                                            <div v-if="style.topics">
                                                <b>{{ $t("searchUI.location") }}<span v-if="locale === 'fr'">&nbsp;</span>: </b>{{ style.country }}
                                            </div>
                                            <p class="sg-text-sm sg-text-gray-500 sg-italic">
                                                {{ $t("searchUI.datePublished") }}<span v-if="locale === 'fr'">&nbsp;</span>: {{
                                                        unixToDate(style.original_published_date, locale)
                                                }}</p>
                                                <p v-if="style.updated !== null" class="sg-text-sm sg-text-gray-500 sg-italic">
                                                    {{ $t("searchUI.dateUpdated") }}: {{ unixToDate(style.updated, locale) }}</p>
                                        </article>
                                    </div>

                                </div>
                                <div v-else class="sg-p-4 sg-flex sg-justify-center">{{ $t("searchUI.noResults") }}
                                </div>
                            </template>
                        </ais-hits>


                    </div>
                </div>
                <!-- <div
                    class="sg-footer-bar sg-space-x-4 sg-fixed sg-flex sg-items-center sg-justify-center sg-w-full sg-bg-white sg-py-4 sg-px-4 sg-border-t sg-border-gray-200 sg-bottom-0 sg-left-0 sg-z-50">


                    <ais-stats class="sg-text-sm sg-text-gray-500">
                        <template v-slot="{ nbPages, nbHits, page }">
                            {{ $t("searchUI.showingPage") }} {{ page + 1 }} {{ $t("searchUI.showingPageOf") }} {{
                                    nbPages
                            }}
                        </template>
                    </ais-stats>
                    <ais-pagination
                        :class-names="{ 'ais-Pagination-item--selected': 'sg-bg-primary-500 sg-text-white sg-border-primary-700 sg-relative sg-z-10', 'ais-Pagination-item--firstPage': 'sg-border-l', 'ais-Pagination-item': 'sg-bg-white sg-flex sg-items-center sg-border-t sg-border-b sg-border-r sg-border-base hover:sg-bg-primary-500 hover:sg-border-primary-700 hover:sg-text-white' }" />
                    <ais-stats class="sg-text-sm sg-text-gray-500">
                        <template v-slot="{ nbPages, nbHits, page }">
                            {{ $t("searchUI.xStylesFound") }} {{ nbHits }} {{ $t("searchUI.xStylesFound2") }}
                        </template>
                    </ais-stats>
                </div> -->
            </ais-instant-search>
        </div>
    </PageStructure>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import algoliasearch from 'algoliasearch/lite';
import { useAppStore } from '@/stores/AppStore'

import { useAisClasses } from '@/composables/useAisClasses';
import { useAlgolia } from "@/composables/useAlgolia.js";
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import PageStructure from '@/components/PageStructure.vue';
import {
    createInsightsMiddleware
} from 'instantsearch.js/es/middlewares';
import aa from 'search-insights';
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from '@headlessui/vue';
import { unixToDate, unixToFullDate } from '@/utils/unixToDate.js';
import GPLoader from '@/components/GPLoader.vue';

//////////////////////////////////////////////////////////////////////////////////////////////
// Composables
//////////////////////////////////////////////////////////////////////////////////////////////

const { locale, t } = useI18n()
const { index } = useAlgolia();
const route = useRoute()
const { searchBoxClasses, voiseSearchClasses, aisRefinementListClasses } = useAisClasses();
const { updateLanguage } = useAppStore();

console.log(route.params.lang);

if (route.params.lang) {
    updateLanguage(route.params.lang);
    locale.value = route.params.lang
}
//////////////////////////////////////////////////////////////////////////////////////////////
// Init
//////////////////////////////////////////////////////////////////////////////////////////////

const searchClient = algoliasearch(
    process.env.VUE_APP_ALGOLIA_APP_ID,
    process.env.VUE_APP_ALGOLIA_SEARCH_ONLY_API_KEY,
);
aa('init', {
    appId: process.env.VUE_APP_ALGOLIA_APP_ID,
    apiKey: process.env.VUE_APP_ALGOLIA_SEARCH_ONLY_API_KEY,
});
aa('setUserToken', 'user1')

//////////////////////////////////////////////////////////////////////////////////////////////
// Refs & Setup
//////////////////////////////////////////////////////////////////////////////////////////////
const loading = ref(true);
const hasRefinements = ref(false)
const totalItems = ref(0)
const languageSettings = reactive({});


onMounted(async () => {
    const i = await index.search('');
    totalItems.value = i.nbHits;
});



switch (locale.value) {
    case 'en':
        languageSettings.language = 'en';
        languageSettings.indexName = 'Style Guide English'
        break;

    case 'es':
        languageSettings.language = 'es';
        languageSettings.indexName = 'Style Guide Spanish'
        break;

    case 'fr':
        languageSettings.language = 'fr';
        languageSettings.indexName = 'Style Guide French'
        break;

    default:
        break;
}

const transformRationales = (items) => {
    // function to translate labels to spanish
    const translateLabel = (label) => {
        if (locale.value === 'en') return label;
        switch (label) {
            case 'precision':
                return 'Precisión';
            case 'dignity':
                return 'Dignidad';
            case 'dignity & precision':
                return 'Dignidad y precisión';
            default:
                return label;
        }
    }
    return items.map(item => ({
        ...item,
        label: translateLabel(item.label),
    }));
}

const transformRefinements = (items) => {
    const translateLabel = (label) => {
        if (locale.value === 'en') {
            switch (label) {
                case 'topics':
                    return t("searchUI.topics");
                case 'country':
                    return t("searchUI.locations");
                case 'rationales_category':
                    return t("searchUI.rationale");
                case 'Is Ban':
                    return t("searchUI.outrightBan");
                default:
                    return label;
            }
        } else {

            switch (label) {
                case 'topics':
                    return t("searchUI.topics");
                case 'country':
                    return t("searchUI.locations");
                case 'rationales_category':
                    return t("searchUI.rationales");
                case 'Is Ban':
                    return t("searchUI.outrightBan");
                default:
                    return label;
            }
        }
    }
    return items.map(item => ({
        ...item,
        label: translateLabel(item.label),
    }));
}

// const splitArrayByCount = (array, chunk = 20) => {
//     let i,
//         j,
//         tempArray = [];

//     for (i = 0, j = array.length; i < j; i += chunk) {
//         tempArray.push(array.slice(i, i + chunk));
//     }

//     return tempArray;
// }
const insightsMiddleware = createInsightsMiddleware({
    insightsClient: aa,
    // onEvent: (event) => {
    //     const { insightsMethod, payload, widgetType, eventType } = event;
    //     const newObjectIDPayload = splitArrayByCount(payload.objectIDs);

    //     // Send the event to Algolia in chunks of 20
    //     for (var i = 0; i < newObjectIDPayload.length; i++) {
    //         let tempPayLoad = payload;
    //         tempPayLoad.objectIDs = newObjectIDPayload[i];

    //         aa(insightsMethod, tempPayLoad);
    //     }
    // }
});

const middlewares = ref([insightsMiddleware]);

const setTopicQueryParam = () => {
    const topic = route.query.topic;
    if (topic) {
        return {
            topics:
                [route.query.topic]
        };
    }
    return {};
}

const initialUiState = ref({
    [languageSettings.indexName]: {
        refinementList: setTopicQueryParam()
    },
});

const searchFunction = async (helper) => {
    await helper.search();
    // console.log(helper.state.hierarchicalFacetsRefinements.deviation.length);
    const check = () => {
        let has = false;
        if (helper.state.disjunctiveFacetsRefinements.country.length) has = true;
        // if (helper.state.hierarchicalFacetsRefinements.deviation.length) has = true;
        if (helper.state.disjunctiveFacetsRefinements.rationales_category.length) has = true;
        if (helper.state.disjunctiveFacetsRefinements.topics.length) has = true;
        return has;
    }
    if (check()) {
        hasRefinements.value = true;
    } else {
        hasRefinements.value = false;
    }
    await new Promise(resolve => setTimeout(resolve, 3000));
    loading.value = false;
}

</script>

<style scoped>
.sg-footer-bar {
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.05);
}

.sg-clear-all-refinements {
    padding: 5px 12px;
    border: 1px solid rgb(209 213 219);
    background-color: white;
    display: flex;
    display: inline-flex;
    font-size: 0.875rem;
    line-height: 1.75;
    font-size: 12px;
    height: 32px;
    align-items: center;
}

.sg-clear-all-refinements:hover {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(234 84 75 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(254 104 95 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
</style>