<template>
    <div v-if="loading">
        <GPLoader :size="100" />
    </div>
    <div v-else>
        <router-link :to="{ name: 'style-details', params: { slug: item.slug, lang: locale } }"
            v-for="(item, index) in topSearches" :key="item.id"
            class="hover:sg-bg-gray-50 sg-px-2 sg-block sg-text-sm sg-py-3"
            :class="[{ 'sg-border-b': index !== topSearches.length - 1 }]">

            {{ item.name }}
        </router-link>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useI18n } from 'vue-i18n'

import { useAlgolia } from "@/composables/useAlgolia.js";
import GPLoader from "./GPLoader.vue";

const { locale } = useI18n()
const { index } = useAlgolia();

const topSearches = ref([]);
const languageSettings = ref({});
const loading = ref(true);

const getTopSearches = async (url = '') => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'X-Algolia-API-Key': process.env.VUE_APP_ALGOLIA_API_KEY,
            'X-Algolia-Application-Id': process.env.VUE_APP_ALGOLIA_APP_ID
        },
    });
    const rawResponse = await response.json();
    const getHit = async (hit) => {
        const i = await index.getObject(hit);
        return i
    };

    const merged = Promise.all(rawResponse.hits.map(async (item) => {
        const { name, slug } = await getHit(item.hit)
        return {
            id: item.hit,
            name,
            slug
        };
    }))
    return merged;
}



switch (locale.value) {
    case 'en':
        languageSettings.language = 'en';
        languageSettings.indexName = 'Style Guide English'
        break;

    case 'es':
        languageSettings.language = 'es';
        languageSettings.indexName = 'Style Guide Spanish'
        break;

    case 'fr':
        languageSettings.language = 'fr';
        languageSettings.indexName = 'Style Guide French'
        break;

    default:
        break;
}

console.log('languageSettings.indexName', languageSettings.indexName);

onMounted(async () => {
    topSearches.value = await getTopSearches(`https://analytics.algolia.com/2/hits?index=${languageSettings.indexName}&clickAnalytics=true`);
    loading.value = false;
})
</script>

<style lang="scss" scoped>
</style>