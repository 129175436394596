<template>


    <ais-instant-search :search-client="searchClient" :index-name="languageSettings.indexName"
        :middlewares="middlewares">
        <ais-configure :click-analytics.camel="true" :hits-per-page.camel="20"
            :attributesToHighlight="['name', 'rule', 'rationale']" />
        <div class="sg-absolute sg-left-4 sg-top-1/2 -sg-translate-y-1/2 sg-z-30">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="sg-w-[24px] sg-text-gray-700">
                <path fill="currentColor"
                    d="m22.241 24-7.414-7.414c-1.559 1.169-3.523 1.875-5.652 1.885h-.002c-.032 0-.07.001-.108.001-5.006 0-9.065-4.058-9.065-9.065 0-.038 0-.076.001-.114v.006c0-5.135 4.163-9.298 9.298-9.298s9.298 4.163 9.298 9.298c-.031 2.129-.733 4.088-1.904 5.682l.019-.027 7.414 7.414zm-12.942-21.487c-3.72.016-6.73 3.035-6.73 6.758 0 3.732 3.025 6.758 6.758 6.758s6.758-3.025 6.758-6.758c0-1.866-.756-3.555-1.979-4.778-1.223-1.223-2.912-1.979-4.778-1.979-.01 0-.02 0-.03 0h.002z" />
            </svg>
        </div>
        <div class="sg-relative">

            <ais-search-box>
                <template v-slot="{ currentRefinement, isSearchStalled, refine }">

                    <button v-if="searchTerm.length" @click="searchTerm = ''"
                        class="sg-absolute sg-z-30 sg-right-5 sg-top-5">

                        <div
                            class="sg-bg-gray-100 sg-rounded-full sg-w-8 sg-h-8 sg-flex sg-items-center sg-justify-center">
                            <svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" class="sg-w-6">
                                <path
                                    d="M12 11.2929l4.14645-4.14645c.195262-.195262.511845-.195262.707107 0 .195262.195262.195262.511845 0 .707107l-4.14645 4.14645 4.14645 4.14645c.195262.195262.195262.511845 0 .707107 -.195262.195262-.511845.195262-.707107 0l-4.14645-4.14645 -4.14645 4.14645c-.195262.195262-.511845.195262-.707107 0 -.195262-.195262-.195262-.511845 0-.707107l4.14645-4.14645 -4.14645-4.14645c-.195262-.195262-.195262-.511845 0-.707107 .195262-.195262.511845-.195262.707107 0Z"
                                    fill="#000"></path>
                            </svg>
                        </div>

                    </button>
                    <input type="input" :value="searchTerm" :placeholder="$t('searchUI.starttyping')"
                        @keydown="showResults()" @input="onSearchInput($event, refine)" class="focus:sg-ring-base-dark focus:sg-border-base-dark focus:sg-outline-none sg-w-full sg-h-[70px]
                    sg-border sg-border-gray-300 sg-px-2 sg-pl-16 sg-text-lg sg-rounded-none sg-relative sg-z-20">
                    <div
                        class="sg-shadow-xl sg-w-[98%] sg-opacity-50 sg-h-full sg-absolute sg-top-0 sg-left-1/2 -sg-translate-x-1/2 sg-mx-auto sg-z-0">
                    </div>
                    <div v-if="searchTerm"
                        class="sg-border sg-border-base-dark sg-absolute -sg-mt-1 sg-top-[70px] sg-left-0 sg-z-50 sg-bg-white sg-w-full sg-max-h-[400px] sg-overflow-scroll sg-shadow-xl">
                        <div v-if="!showingResults" class="sg-p-4 sg-flex sg-justify-center">
                            <GPLoader :size="100" />
                        </div>
                        <ais-hits v-else>
                            <template v-slot="{ items, sendEvent }">
                                <div v-if="items.length">
                                    <router-link v-for="style in items" :key="style.objectID"
                                        :to="{ name: 'style-details', params: { slug: style.slug, lang: locale } }"
                                        @click="sendEvent('click', style, 'Style Opened'); searchTerm = '';"
                                        class="sg-block sg-p-4 sg-border-b sg-border-base sg-w-full hover:sg-bg-gray-100">
                                        <h2 class="sg-text-lg sg-mb-1 sg-font-semibold">
                                            {{ style.name }}
                                        </h2>
                                        <div class="sg-text-sm">
                                            <b>{{ $t("searchUI.rule") }}: </b>
                                            <span v-html="style.rule" class="sg-whitespace-pre-line"></span>
                                        </div>
                                    </router-link>
                                </div>
                                <div v-else class="sg-p-4 sg-flex sg-justify-center">{{ $t("searchUI.noResults") }}
                                </div>
                                <div
                                    class="sg-sticky sg-bottom-0 sg-left-0 sg-bg-gray-50 sg-py-2 sg-px-4 sg-border-t -sg-mt-1 sg-border-base-dark sg-text-xs">
                                    <ais-stats>
                                        <template v-slot="{ nbHits }">
                                            {{ $t("searchUI.xStylesFound") }} {{ nbHits }}
                                            {{ $t("searchUI.xStylesFound2") }}
                                        </template>
                                    </ais-stats>
                                </div>
                            </template>
                        </ais-hits>
                    </div>
                </template>
            </ais-search-box>
        </div>

        <!-- <div
                        class="sg-shadow-xl sg-w-[98%] sg-opacity-50 sg-h-full sg-absolute sg-top-0 sg-left-1/2 -sg-translate-x-1/2 sg-mx-auto sg-z-0">
                    </div> -->


    </ais-instant-search>
</template>

<script setup>
import { ref, reactive } from 'vue';
import algoliasearch from 'algoliasearch/lite';
import { useAlgolia } from "../composables/useAlgolia.js";
import { useAisClasses } from '../composables/useAisClasses';
import { useI18n } from 'vue-i18n'

import aa from 'search-insights';

import {
    createInsightsMiddleware
} from 'instantsearch.js/es/middlewares';
import GPLoader from './GPLoader.vue';
//////////////////////////////////////////////////////////////////////////////////////////////
// Composables
//////////////////////////////////////////////////////////////////////////////////////////////
const { index } = useAlgolia();
const { searchBoxClasses, voiseSearchClasses } = useAisClasses();
const { locale } = useI18n()

const languageSettings = reactive({});

// if (locale.value === 'en') {
//     languageSettings.indexName = 'sg_en_instant_search'
// } else {
//     languageSettings.indexName = 'sg_es_instant_search'
// }

switch (locale.value) {
    case 'en':
        languageSettings.indexName = 'sg_en_instant_search'
        break;

    case 'es':
        languageSettings.indexName = 'sg_es_instant_search'
        break;

    case 'fr':
        languageSettings.indexName = 'sg_fr_instant_search'
        break;

    default:
        break;
}

const searchTerm = ref('');
const showingResults = ref(false);

const showResults = async () => {
    // wait 1 second using await promise
    await new Promise(resolve => setTimeout(resolve, 1900));

    // showingResults true 
    showingResults.value = true;

}

const onSearchInput = (e, refine) => {
    // if event value is empty, set showingResults to false
    if (e.target.value === '') {
        showingResults.value = false;
    }
    refine(e.currentTarget.value);
    searchTerm.value = e.currentTarget.value
}

const searchClient = algoliasearch(
    process.env.VUE_APP_ALGOLIA_APP_ID,
    process.env.VUE_APP_ALGOLIA_API_KEY,
);

aa('init', {
    appId: process.env.VUE_APP_ALGOLIA_APP_ID,
    apiKey: process.env.VUE_APP_ALGOLIA_API_KEY,
});

aa('setUserToken', 'user1')

const splitArrayByCount = (array, chunk = 20) => {
    let i,
        j,
        tempArray = [];

    for (i = 0, j = array.length; i < j; i += chunk) {
        tempArray.push(array.slice(i, i + chunk));
    }

    return tempArray;
}

const insightsMiddleware = createInsightsMiddleware({
    insightsClient: aa,
    onEvent: (event) => {
        const { insightsMethod, payload, widgetType, eventType } = event;
        const newObjectIDPayload = splitArrayByCount(payload.objectIDs);

        // Send the event to Algolia in chunks of 20
        for (var i = 0; i < newObjectIDPayload.length; i++) {
            let tempPayLoad = payload;
            tempPayLoad.objectIDs = newObjectIDPayload[i];

            aa(insightsMethod, tempPayLoad);
        }
    }
});

const middlewares = ref([insightsMiddleware]);



</script>

<style lang="scss" scoped>
</style>