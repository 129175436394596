export const unixToDate = (unix, locale) => {
  const date = new Date(unix * 1000).toLocaleString(locale, {
    month: "long",
    year: "numeric"
  });
  return date;
};
export const unixToFullDate = (unix, locale) => {
  const date = new Date(unix * 1000).toLocaleString(locale, {
    month: "long",
    year: "numeric",
    day: "numeric"
    // hour: "numeric",
    // minute: "numeric"
  });
  return date;
};
