import { createApp } from "vue";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import { createMetaManager } from "vue-meta";

import App from "./App.vue";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import "@/assets/css/app.css";

import VueSupabase from "vue-supabase";
import InstantSearch from "vue-instantsearch/vue3/es";
import { strings } from "@/translation/strings";

import ScrollPanel from "primevue/scrollpanel";

import router from "./router";

const app = createApp(App);

app.component("ScrollPanel", ScrollPanel);

// let i18n = "";
// router.beforeEach((to, from, next) => {
//   let lang = to.query.lang;
//   if (!lang) {
//     lang = "en";
//   }
//   i18n = createI18n({
//     locale: lang,
//     messages: strings
//   });
//   app.use(i18n);

//   return next();
// });
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const { language } = JSON.parse(localStorage.getItem("AppStore")) || {
  language: "en"
};

let i18n = createI18n({
  locale: language,
  messages: strings
});

app.use(i18n);
app.use(createMetaManager());
app.use(InstantSearch);

if (i18n.global.locale === "es") {
  app.use(VueSupabase, {
    supabaseUrl: process.env.VUE_APP_ES_SUPABASE_URL,
    supabaseKey: process.env.VUE_APP_ES_SUPABASE_API
  });
}

if (i18n.global.locale === "en") {
  app.use(VueSupabase, {
    supabaseUrl: process.env.VUE_APP_SUPABASE_URL,
    supabaseKey: process.env.VUE_APP_SUPABASE_API
  });
}

if (i18n.global.locale === "fr") {
  app.use(VueSupabase, {
    supabaseUrl: process.env.VUE_APP_FR_SUPABASE_URL,
    supabaseKey: process.env.VUE_APP_FR_SUPABASE_API
  });
}

app.use(pinia);
app.use(router);

// await router.isReady();

app.mount("#app");
