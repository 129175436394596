<template>
    <PageStructure>
        <div v-if="loading"
            class="sg-w-full sg-h-full sg-fixed sg-top-0 sg-left-0 sg-bg-white sg-flex sg-items-center sg-justify-center sg-z-50">
            <GPLoader />
        </div>
        <div v-else>
            <div class="lg:sg-grid sg-grid-cols-12 sg-gap-10">
                <div class="sg-sticky sg-col-span-12 -sg-mt-[60px] sg-mb-10 sg-top-0 sg-z-30">
                    <InstantSearch />
                </div>
                <div class="sg-col-span-3">
                    <div class="sg-space-y-10 sg-sidebar-items sg-pb-10">
                        <div class="sg-sidebar-item">
                            <router-link to="/" class="sg-button-outline sg-w-full sg-bg-white">{{
                                    $t("searchUI.allStyles")
                            }}
                            </router-link>
                        </div>
                        <div v-if="style.related_styles.length" class="sg-sidebar-item sg-bg-white sg-border sg-p-6">
                            <h2 class="sg-text-lg sg-font-medium sg-mb-2 sg-pb-2">{{ $t("searchUI.relatedStyles") }}
                            </h2>
                            <div class="">
                                <router-link v-for="(relStyle, index) in style.related_styles" :key="index"
                                    :to="{ name: 'style-details', params: { slug: relStyle.slug, lang: locale } }"
                                    class="hover:sg-bg-gray-50 sg-px-2 sg-block sg-text-sm sg-py-3"
                                    :class="[{ 'sg-border-b': index !== style.related_styles.length - 1 }]">

                                    {{ relStyle.name }}</router-link>
                            </div>
                        </div>

                        <div v-if="style.topics.length" class="sg-sidebar-item sg-bg-white sg-border sg-p-6">
                            <h2 class="sg-text-lg sg-font-medium sg-mb-2 sg-pb-2">{{ $t("searchUI.relatedTopics") }}
                            </h2>
                            <div class="">
                                <router-link :to="`/?topic=${topic}`" v-for="(topic, index) in style.topics"
                                    :key="index" class="hover:sg-bg-gray-50 sg-px-2 sg-block sg-text-sm sg-py-3"
                                    :class="[{ 'sg-border-b': index !== style.topics.length - 1 }]">
                                    {{ topic }}
                                </router-link>
                            </div>
                        </div>
                        <div class="sg-sidebar-item sg-bg-white sg-border sg-p-6">
                            <h2 class="sg-text-lg sg-font-medium sg-mb-2 sg-pb-2">{{ $t("searchUI.trendingStyles") }}
                            </h2>
                            <TrendingStyles />
                        </div>
                        <div v-if="style.note" class="sg-sidebar-item sg-bg-white sg-border sg-p-6">
                            <h2 class="sg-text-lg sg-font-medium sg-mb-2 sg-pb-2">
                                {{ $t("searchUI.note") }}
                            </h2>
                            <p class=" sg-italic">{{ style.note }}</p>
                        </div>
                    </div>
                </div>
                <div class="sg-col-span-9">
                    <article
                        class="sg-space-y-5 sg-bg-white sg-transition-shadow sg-border sg-p-8 sg-block sg-border-base-light">
                        <h2 class="sg-text-3xl sg-font-semibold sg-flex sg-items-center sg-justify-between">
                            {{ style.name }}
                        </h2>


                        <p v-if="style.deviation === 'deviation'"
                            class="sg-text-sm sg-text-gray-600 sg-font-medium sg-italic">
                            This is a deviation from AP Style.
                        </p>
                        <p v-if="style.deviation === 'previous_deviation'"
                            class="sg-text-sm sg-text-gray-600 sg-font-medium sg-italic">
                            This is no longer a deviation from AP Style.
                        </p>
                        <div>
                            <b>{{ $t("searchUI.rule") }}<span v-if="locale === 'fr'">&nbsp;</span>: </b>
                            <span v-html="style.rule" class=" sg-whitespace-pre-line"></span>
                        </div>
                        <div>
                            <b>{{ $t("searchUI.rationale") }}<span v-if="locale === 'fr'">&nbsp;</span>: </b>
                            {{ style.rationale }}
                        </div>
                        <div v-if="style.related_styles.length">
                            <b>{{ $t("searchUI.seeAlso") }}: </b>
                            <router-link v-for="(relStyle, index) in style.related_styles" :key="index"
                                :to="{ name: 'style-details', params: { slug: relStyle.slug, lang: locale } }"
                                class=" sg-text-primary-500 hover:sg-underline">{{ relStyle.name
                                }}<span v-if="index !== style.related_styles.length - 1"><span v-if="locale === 'fr'">&nbsp;</span>; </span>
                            </router-link>

                        </div>
                        <div v-if="style.topics">
                            <b>{{ $t("searchUI.relatedTopics") }}<span v-if="locale === 'fr'">&nbsp;</span>: </b>
                            <router-link :to="`/?topic=${topic}`" v-for="(topic, index) in style.topics" :key="index"
                                class=" sg-inline sg-text-primary-500 hover:sg-underline">
                                {{
                                        topic
                                }}<span v-if="index !== style.topics.length - 1">, </span>
                            </router-link>


                        </div>
                        <div v-if="style.topics">
                            <b>{{ $t("searchUI.location") }}<span v-if="locale === 'fr'">&nbsp;</span>: </b>{{ style.country }}
                        </div>
                        <p class="sg-text-sm sg-text-gray-500 sg-italic">
                            {{ $t("searchUI.datePublished") }}<span v-if="locale === 'fr'">&nbsp;</span>: {{
                                unixToDate(style.original_published_date, locale)
                            }}</p>
                            <p v-if="style.updated !== null" class="sg-text-sm sg-text-gray-500 sg-italic">
                                {{ $t("searchUI.dateUpdated") }}: {{ unixToDate(style.updated, locale) }}</p>
                    </article>
                    <div
                        class="sg-bg-base-light sg-bg-opacity-30 sg-border sg-border-base sg-mt-12 sg-p-10 sg-space-y-6">
                        <h2 class="sg-text-2xl sg-font-semibold">{{ $t("searchUI.askGlobalPress") }}</h2>
                        <!-- <p>
                        Sed ut mi sagittis, dignissim ex eget, sodales quam. Aenean laoreet imperdiet
                        dolor vel finibus. Phasellus faucibus dui velit, eget cursus sem.
                    </p> -->
                        <form @submit.prevent="onSubmitQuestion">
                            <textarea name="question" v-model="QAform.question" rows="6"
                                class="sg-w-full sg-p-4 sg-border sg-border-base-light"></textarea>
                            <button type="submit" class="sg-button sg-mt-2">{{ $t('searchUI.submitQuestion') }}</button>
                        </form>
                        <div>
                            <h3 class="sg-mb-6 sg-text-xl">{{ $t("searchUI.previoustlyAnswered") }}</h3>
                            <div v-if="questions.length" class="sg-space-y-6">
                                <div v-for="question in questions" :key="question.id">
                                    <h4 class="sg-font-semibold sg-mb-2">{{ question.question }}</h4>
                                    <div v-if="question.answer">{{ question.answer }}</div>
                                    <div v-else>{{ $t("searchUI.notAnswered") }}</div>
                                </div>
                            </div>
                            <div v-else>{{ $t("searchUI.noQuestions") }}</div>
                            <router-link to="/qa-archives"
                                class="sg-text-primary-500 sg-underline sg-font-semibold sg-mt-4 sg-block">
                                {{ $t("searchUI.seeAllQuestionsAndAnswers") }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PageStructure>
</template>

<script setup>
import { ref, onMounted, watch, reactive } from "vue";
import { useRoute } from "vue-router";
import { createToaster } from "@meforma/vue-toaster";
import { useSupabase } from "vue-supabase";
import { useAlgolia } from "@/composables/useAlgolia.js";
import { unixToDate, unixToFullDate } from "@/utils/unixToDate.js";
import { useI18n } from 'vue-i18n'
import InstantSearch from "@/components/InstantSearch.vue";
import GPLoader from "@/components/GPLoader.vue";
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/stores/AppStore'
import TrendingStyles from '@/components/TrendingStyles.vue';
import PageStructure from "@/components/PageStructure.vue";

//////////////////////////////////////////////////////////////////////////////////////////////
// Composables
//////////////////////////////////////////////////////////////////////////////////////////////
const toaster = createToaster({ position: "top" });
const { index } = useAlgolia();
const { locale, t } = useI18n()
const supabase = useSupabase();

const route = useRoute();
const style = ref({});
const QAform = reactive({});
const loading = ref(true);
const questions = ref([]);
const { updateLanguage } = useAppStore();

if (route.params.lang) {
    updateLanguage(route.params.lang);
    locale.value = route.params.lang
}

console.log(route.params.lang);

const onSubmitQuestion = async () => {
    console.log(style, style.value, style.value.ObjectID);
    QAform.style_id = style.value.objectID;
    const { data, error } = await supabase.from("questions_answers").insert([QAform]);

    if (!error) {
        toaster.success(t('searchUI.questionSubmitted'));
        QAform.question = "";
        QAform.style_id = "";
        await getQuestions();
    } else {
        if (error.code === "23502") toaster.error(t('searchUI.questionSubmitError'));
        else toaster.error(error.message);
    }
};

const getQuestions = async () => {
    const { data, error } = await supabase
        .from("questions_answers")
        .select("*")
        .match({ style_id: style.value.objectID })
        .eq("published", true);

    if (!error) {
        questions.value = data;
    } else {
        console.log(error.message);
    }
};

watch(
    route,
    async (to) => {

        loading.value = true;

        if (to.path.includes('/style/')) {
            const i = await index.findObject(hit => hit.slug == route.params.slug);
            style.value = i.object;
            await getQuestions();
            loading.value = false;
        }


    },
    { flush: "pre", immediate: true, deep: true }
);
</script>

<style lang="scss" scoped>
</style>
