<template>
    <PageStructure>
        <div class="lg:sg-grid sg-grid-cols-12 sg-gap-10">
            <div class="sg-relative sg-col-span-12 -sg-mt-[60px] sg-mb-16">
                <InstantSearch />
            </div>
            <div class="sg-col-span-3">
                <div class="sg-pb-10 sg-space-y-10 sg-sidebar-items">
                    <div class="sg-sidebar-item">
                        <router-link to="/" class="sg-button-outline sg-w-full sg-bg-white">{{ $t("searchUI.allStyles")
                        }}
                        </router-link>
                    </div>
                    <div class="sg-sidebar-item sg-bg-white sg-border sg-p-6">
                        <h2 class="sg-text-lg sg-font-medium sg-mb-2 sg-pb-2">
                            {{ $t("searchUI.trendingStyles") }}
                        </h2>

                        <TrendingStyles />
                    </div>

                    <div class="sg-sidebar-item sg-bg-white sg-border sg-p-6">
                        <h2 class="sg-text-lg sg-font-medium sg-mb-2 sg-pb-2">
                            {{ $t("searchUI.recentEntries") }}
                        </h2>
                        <RecentStyles />
                    </div>
                </div>
            </div>
            <div class="sg-space-y-12 sg-col-span-9">
                <div
                    class="sg-space-y-5 sg-bg-white sg-transition-shadow sg-border sg-p-8 sg-block sg-border-base-light">
                    <h3 class="sg-mb-3 sg-text-xl sg-font-semibold">
                        {{ $t("searchUI.latestQuestions") }}
                    </h3>
                    <div v-if="questions.length" class="sg-space-y-6">
                        <div v-for="question in questions" :key="question.id">
                            <router-link v-if="question.styles" :to="{
                                name: 'style-details',
                                params: { slug: question.styles.slug, lang: locale },
                            }" class="sg-text-primary-500">
                                <h4 class="sg-mb-1">{{ question.styles.name }}</h4>
                            </router-link>
                            <h5 class="sg-font-semibold">{{ question.question }}</h5>
                            <div v-if="question.answer">{{ question.answer }}</div>
                            <div v-else>{{ $t("searchUI.notAnswered") }}</div>
                        </div>
                    </div>
                </div>
                <div class="sg-bg-base-light sg-bg-opacity-30 sg-border sg-border-base sg-p-10 sg-space-y-6">
                    <h2 class="sg-text-2xl sg-font-semibold">
                        {{ $t("searchUI.askGlobalPress") }}
                    </h2>
                    <!-- <p>
                        Sed ut mi sagittis, dignissim ex eget, sodales quam. Aenean laoreet imperdiet
                        dolor vel finibus. Phasellus faucibus dui velit, eget cursus sem.
                    </p> -->
                    <form @submit.prevent="onSubmitQuestion">
                        <textarea name="question" v-model="QAform.question" rows="6"
                            class="sg-w-full sg-p-4 sg-border sg-border-base-light"></textarea>
                        <button type="submit" class="sg-button sg-mt-2">
                            {{ $t("searchUI.submitQuestion") }}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </PageStructure>
</template>

<script setup>
import { ref, onMounted, reactive } from "vue";
import { createToaster } from "@meforma/vue-toaster";
import { useSupabase } from "vue-supabase";
import { useI18n } from "vue-i18n";
import InstantSearch from "@/components/InstantSearch.vue";
import algoliarecommend from "@algolia/recommend";
import TrendingStyles from "@/components/TrendingStyles.vue";
import RecentStyles from "@/components/RecentStyles.vue";
import PageStructure from "../PageStructure.vue";

//////////////////////////////////////////////////////////////////////////////////////////////
// Composables
//////////////////////////////////////////////////////////////////////////////////////////////
const toaster = createToaster({ position: "top" });
const supabase = useSupabase();
const { locale, t } = useI18n();
// const recommendClient = algoliarecommend(
//     'V9ZZWETQIG',
//     'a1f8c66d51394cc606ae488af2878477'
// );
// console.log(recommendClient.getRecommendations([
//     {
//         indexName: 'Style Guide English',
//         model: 'Trending items'
//     },
// ]));

const QAform = reactive({});
const questions = ref([]);

const onSubmitQuestion = async () => {
    const { data, error } = await supabase.from("questions_answers").insert([QAform]);

    if (!error) {
        toaster.success(t("searchUI.questionSubmitted"));
        QAform.question = "";
        await getQuestions();
    } else {
        if (error.code === "23502") toaster.error(t("searchUI.questionSubmitError"));
        else toaster.error(error.message);
    }
};

const getQuestions = async () => {
    const { data, error } = await supabase
        .from("questions_answers")
        .select(`question, styles(id, name, slug)`)
        .eq("published", true);

    if (!error) {
        questions.value = data;
    } else {
        console.log(error.message);
    }
};

onMounted(async () => {
    await getQuestions();
});
</script>

<style scoped>
</style>
