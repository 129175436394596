<template>
    <footer class="sg-bg-[#f3f2ef] sg-border-t sg-border-base">
        <div class="sg-p-8 sg-border-b sg-border-gray-300">
            <div class="sg-container-xl sg-flex sg-items-center sg-justify-center">
                <a href="https://globalpressjournal.com/"><img class="sg-w-96" src="/images/gpj-logo.png"
                        alt="Global Press Journal" title="Global Press Journal"></a>
            </div>
        </div>
        <nav class="sg-py-6 sg-border-b sg-border-gray-300" role="navigation"
            aria-label="Footer brands navigation menu">
            <div class="sg-container-xl">

                <ul id="menu-footer-main-nav" class="sg-justify-center md:sg-flex sg-text-center sg-mb-0">
                    <li v-for="(item, i) in footerMainMenu" :key="item.id"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-10050797 sg-uppercase text-sm sg-px-4 md:sg-border-gray-500 sg-ml-px"
                        :class="{ 'md:sg-border-l': i !== 0 }">
                        <a target="_blank" rel="noopener noreferrer" :href="item.url">{{ item.title }}</a>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="sg-py-6 sg-border-b sg-border-gray-300 ">
            <div
                class="sg-space-y-12 sg-container-xl md:sg-grid sg-grid-cols-3 sg-gap-12 sg-text-center md:sg-space-y-0">
                <div class="sg-text-center">
                    <h2 class="sg-text-xl sg-text-center sg-font-serif sg-italic sg-font-semibold sg-mb-2">Contact
                    </h2>
                    <p class="sg-text-center sg-mb-6">5636 Connecticut Ave NW<br>
                        PO Box 42557<br>
                        Washington, DC 20015<br>
                        info@globalpressjournal.com</p>
                    <p class="sg-text-center">202-448-9666</p>
                </div>
                <nav role="navigation" aria-label="Footer information navigation menu">
                    <h2 class="sg-text-xl sg-text-center sg-font-serif sg-italic sg-font-semibold sg-mb-2">
                        Information</h2>
                    <ul id="menu-footer-menu" class="menu">
                        <li v-for="(item, i) in footerInfoNav" :key="item.id" class="sg-mb-1"><a target="_blank"
                                rel="noopener noreferrer" :href="item.url">{{ item.title }}</a></li>
                    </ul>
                </nav>
                <nav role="navigation" aria-label="Footer brands navigation menu">
                    <h2 class="sg-text-xl sg-text-center sg-font-serif sg-italic sg-font-semibold sg-mb-2">Brands
                    </h2>
                    <ul id="menu-footer-menu" class="menu">
                        <li v-for="(item, i) in footerBrandNav" :key="item.id" class="sg-mb-1"><a target="_blank"
                                rel="noopener noreferrer" :href="item.url">{{ item.title }}</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="sg-border-b sg-border-gray-300">
            <div class="sg-container-xl">
                <div class="md:sg-flex sg-justify-center">
                    <div class="border-b sg-p-6 md:sg-border-r sg-border-gray-300 sg-flex sg-items-center">
                        <div class="md:sg-flex sg-items-center sg-space-x-3">
                            <p class="sg-uppercase sg-font-medium sg-text-sm md:sg-mb-0 sg-mb-0">Connect with Global
                                press</p>
                            <nav class="sg-flex sg-space-x-3">
                                <a class="social-link" href="https://www.facebook.com/globalpressjournal/"
                                    title="Visit our Facebook" aria-label="Visit our Facebook">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32"
                                        fill="currentColor">
                                        <path
                                            d=" M32 16c0-8.839-7.167-16-16-16-8.839 0-16 7.161-16 16 0 7.984 5.849 14.604 13.5 15.803v-11.177h-4.063v-4.625h4.063v-3.527c0-4.009 2.385-6.223 6.041-6.223 1.751 0 3.584 0.312 3.584 0.312v3.937h-2.021c-1.984 0-2.604 1.235-2.604 2.5v3h4.437l-0.713 4.625h-3.724v11.177c7.645-1.199 13.5-7.819 13.5-15.803z">
                                        </path>
                                    </svg>
                                </a>
                                <a class="social-link" href="https://www.instagram.com/globalpressjournal/"
                                    title="Visit our Instagram" aria-label="Visit our Instagram">
                                    <svg width="24" height="24" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16 0c-4.349 0-4.891.021-6.593.093 -1.71.08-2.865.349-3.885.745 -1.06.41-1.948.95-2.84 1.84 -.891.88-1.45 1.78-1.849 2.83C.43 6.52.16 7.684.08 9.393c-.08 1.7-.093 2.24-.093 6.593 0 4.34.021 4.891.093 6.59 .08 1.7.349 2.86.745 3.88 .41 1.05.95 1.94 1.849 2.83 .88.89 1.78 1.44 2.83 1.84 1.02.39 2.181.66 3.885.74 1.7.07 2.24.09 6.593.09 4.349 0 4.89-.03 6.593-.1 1.7-.09 2.86-.36 3.88-.75 1.05-.42 1.94-.96 2.83-1.85 .89-.89 1.44-1.78 1.84-2.84 .39-1.02.66-2.19.74-3.89 .07-1.71.09-2.25.09-6.593 0-4.349-.03-4.891-.1-6.593 -.09-1.71-.36-2.88-.75-3.885 -.42-1.06-.96-1.948-1.85-2.84 -.89-.891-1.78-1.45-2.84-1.849C25.4.37 24.23.09 22.53.02 20.82-.06 20.28-.08 15.94-.08Zm0 2.88c4.27 0 4.78.02 6.46.09 1.55.07 2.4.33 2.968.55 .75.29 1.27.63 1.84 1.197 .55.55.9 1.088 1.19 1.83 .22.56.48 1.41.55 2.968 .07 1.68.09 2.199.09 6.46 0 4.27-.03 4.78-.1 6.46 -.09 1.55-.35 2.4-.57 2.968 -.31.75-.65 1.27-1.2 1.84 -.57.55-1.1.9-1.85 1.19 -.56.22-1.42.48-2.98.55 -1.7.07-2.2.09-6.48.09 -4.29 0-4.79-.03-6.48-.1 -1.56-.09-2.42-.35-2.98-.57 -.76-.31-1.29-.65-1.84-1.2 -.57-.57-.93-1.1-1.197-1.85 -.23-.56-.48-1.42-.57-2.98 -.06-1.68-.09-2.2-.09-6.459 0-4.26.02-4.79.08-6.48 .08-1.57.33-2.43.56-2.98 .27-.77.63-1.29 1.197-1.85 .55-.56 1.07-.92 1.83-1.2 .56-.22 1.4-.48 2.96-.56 1.69-.07 2.19-.09 6.47-.09Zm0 4.907c-4.541 0-8.213 3.67-8.213 8.21 0 4.541 3.67 8.21 8.213 8.21 4.541 0 8.21-3.68 8.21-8.22 0-4.541-3.68-8.213-8.22-8.213Zm0 13.54c-2.95 0-5.333-2.39-5.333-5.34 0-2.95 2.385-5.333 5.333-5.333 2.94 0 5.33 2.385 5.33 5.333 0 2.94-2.39 5.33-5.333 5.33ZM26.46 7.44c0 1.06-.87 1.92-1.93 1.92 -1.07 0-1.93-.859-1.93-1.93 0-1.06.86-1.92 1.92-1.92 1.05 0 1.92.86 1.92 1.91Z">
                                        </path>
                                    </svg>
                                </a>
                                <a class="social-link" href="https://twitter.com/GlobalPress" title="Visit our Twitter"
                                    aria-label="Visit our Twitter">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
                                        <path
                                            d="M31.937 6.093c-1.177 0.516-2.437 0.871-3.765 1.032 1.355-0.813 2.391-2.099 2.885-3.631-1.271 0.74-2.677 1.276-4.172 1.579-1.192-1.276-2.896-2.079-4.787-2.079-3.625 0-6.563 2.937-6.563 6.557 0 0.521 0.063 1.021 0.172 1.495-5.453-0.255-10.287-2.875-13.52-6.833-0.568 0.964-0.891 2.084-0.891 3.303 0 2.281 1.161 4.281 2.916 5.457-1.073-0.031-2.083-0.328-2.968-0.817v0.079c0 3.181 2.26 5.833 5.26 6.437-0.547 0.145-1.131 0.229-1.724 0.229-0.421 0-0.823-0.041-1.224-0.115 0.844 2.604 3.26 4.5 6.14 4.557-2.239 1.755-5.077 2.801-8.135 2.801-0.521 0-1.041-0.025-1.563-0.088 2.917 1.86 6.36 2.948 10.079 2.948 12.067 0 18.661-9.995 18.661-18.651 0-0.276 0-0.557-0.021-0.839 1.287-0.917 2.401-2.079 3.281-3.396z">
                                        </path>
                                    </svg>
                                </a>
                            </nav>
                        </div>

                    </div>
                    <div class="sg-p-6">
                        <form
                            class="validate md:sg-flex sg-items-center md:sg-space-x-3 md:sg-space-y-0 sg-space-x-0 sg-space-y-3"
                            action="https://globalpressjournal.us5.list-manage.com/subscribe/post?u=69b5b1f94aae53b4633f871cf&amp;id=f97f12bb11"
                            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                            target="_blank" novalidate>
                            <p class="sg-uppercase sg-font-medium sg-text-sm sg-mb-0">Newsletter Signup</p>
                            <div>
                                <input type="email" value="" name="EMAIL"
                                    class="!sg-w-full !sg-border-0 required sg-newsletter-input !sg-shadow-none !sg-bg-transparent sg-placeholder:italic sg-placeholder:text-gray-100 !sg-mb-0"
                                    id="mce-EMAIL" placeholder="Enter your email address">
                                <div class="response" id="mce-error-response" style="display:none"></div>
                                <div class="response" id="mce-success-response" style="display:none"></div>
                                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text"
                                        name="b_69b5b1f94aae53b4633f871cf_f97f12bb11" tabindex="-1" value=""></div>
                            </div>
                            <input type="submit" value="Submit" name="subscribe" id="mc-embedded-subscribe"
                                class="sg-uppercase sg-font-bold sg-text-sm sg-cursor-pointer">
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios'

const footerMainMenu = ref([]);
const footerBrandNav = ref([]);
const footerInfoNav = ref([]);

const getFooterMainMenu = async () => {
    const res = await axios({
        method: 'get',
        url: 'https://www.globalpressjournal.com/wp-json/menus/v1/menus/16341?nested=1&fields=url,ID,title,meta',
    })
    return res.data;
}
const getFooterBrandMenu = async () => {
    const res = await axios({
        method: 'get',
        url: 'https://www.globalpressjournal.com/wp-json/menus/v1/menus/16304?nested=1&fields=url,ID,title,meta',
    })
    return res.data;
}
const getFooterInfoMenu = async () => {
    const res = await axios({
        method: 'get',
        url: 'https://www.globalpressjournal.com/wp-json/menus/v1/menus/8274?nested=1&fields=url,ID,title,meta',
    })
    return res.data;
}

onMounted(async () => {
    footerMainMenu.value = await getFooterMainMenu()
    footerBrandNav.value = await getFooterBrandMenu()
    footerInfoNav.value = await getFooterInfoMenu()
})

</script>

<style lang="scss" scoped>
</style>