<template>
    <div class="sg-app-wrap">
        <div v-if="loading === true"
            class="sg-w-full sg-h-full sg-fixed sg-top-0 sg-left-0 sg-bg-white sg-flex sg-items-center sg-justify-center sg-z-50">
            <GPLoader />
        </div>
        <Header />
        <div class="sg-min-h-screen sg-pb-24">
            <section class="sg-header sg-bg-base">
                <div class="sg-px-6 sg-container-xl sg-mx-auto">
                    <div class="sg-py-24 sg-pb-36 lg:sg-grid sg-grid-cols-12">
                        <div class="sg-col-span-6 sg-flex sg-items-center sg-mb-12 lg:sg-mb-0">
                            <div class="sg-space-y-6 ">

                                <h1 class="sg-text-6xl sg-font-semibold sg-max-w-[10ch]">{{ $t("mainLayout.header") }}
                                </h1>
                                <p class="sg-max-w-[30ch] sg-text-2xl">{{ $t("mainLayout.intro") }}</p>
                                <div class="flex items-center space-x-4">
                                    <button type="button" :class="[{ 'sg-lang-button-active': locale === 'en' }]"
                                        class="sg-lang-button" @click="changeLanguage('en');">{{ $t("searchUI.english")
                                        }}</button>
                                    <button type="button" :class="[{ 'sg-lang-button-active': locale === 'es' }]"
                                        class="sg-lang-button" @click="changeLanguage('es');">{{ $t("searchUI.spanish")
                                        }}</button>
                                        <button type="button" :class="[{ 'sg-lang-button-active': locale === 'fr' }]"
                                        class="sg-lang-button" @click="changeLanguage('fr');">{{ $t("searchUI.french")
                                        }}</button>
                                </div>
                            </div>
                        </div>
                        <div class="sg-col-span-6 lg:sg-flex lg:sg-justify-end">
                            <figure class="sg-shadow sg-bg-white sg-p-8 sg-max-w-[400px]">
                                <router-link v-if="locale === 'en'" :to="`${randomImage.url}`" class="sg-block">
                                    <img :src="`/images/${randomImage.fileName}.jpg`" alt="style-guide-illustration"
                                        class="sg-mx-auto lg:sg-mt-0 sg-mt-16" height="350" width="350">
                                </router-link>
                                <router-link v-if="locale === 'es'" :to="`${randomImage.url}`" class="sg-block">
                                    <img :src="`/images/${randomImage.fileName}.jpg`" alt="style-guide-illustration"
                                        class="sg-mx-auto lg:sg-mt-0 sg-mt-16" height="350" width="350">
                                </router-link>
                                <router-link v-if="locale === 'fr'" :to="`${randomImage.url}`" class="sg-block">
                                    <img :src="`/images/${randomImage.fileName}.jpg`" alt="style-guide-illustration"
                                        class="sg-mx-auto lg:sg-mt-0 sg-mt-16" height="350" width="350">
                                </router-link>
                                <div class="sg-text-center sg-pt-8">
                                    <h3 class="sg-font-bold">{{ randomImage.name }}</h3>
                                    <p class="sg-text-sm">{{ $t("mainLayout.artby") }} {{ randomImage.illustrator }}
                                    </p>
                                </div>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <main>
                <div class="sg-p-6 sg-container-xl sg-mx-auto sg-pb-36">
                    <slot />
                </div>

            </main>

        </div>

        <Footer />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useAppStore } from '@/stores/AppStore'
import { useI18n } from 'vue-i18n'
import router from "@/router";
import GPLoader from './GPLoader.vue';
import Header from './Header.vue';
import Footer from './Footer.vue';

const { updateLanguage } = useAppStore();
const { locale } = useI18n();
const loading = ref(false);
const images = ref({
    en: [
        {
            name: "Accent Marks",
            fileName: "GPSG_Mexico_AccentMark_en",
            illustrator: "Sirako - Mexico",
            url: "/en/style/accent-marks",
        },
        {
            name: "Developing World",
            fileName: "GPSG_Argentina_DevelopingWorld_01_en",
            illustrator: "Martina Ardissono - Argentina",
            url: "/en/style/developing-world",
        },
        {
            name: "Disabilities",
            fileName: "GPSG_Argentina_Disabilities_01_en",
            illustrator: "Emilie Maino - Argentina",
            url: "/en/style/disabilities",
        },
        {
            name: "Inmate",
            fileName: "GPSG_Argentina_Inmate_01_en",
            illustrator: "Emilie Maino - Argentina",
            url: "/en/style/inmate",
        },
        {
            name: "America",
            fileName: "GPSG_Mexico_America_01_en",
            illustrator: "Sirako - Mexico",
            url: "/en/style/america-american",
        },
        {
            name: "America",
            fileName: "GPSG_Mexico_America_02_en",
            illustrator: "Chepe Daniel - Mexico",
            url: "/en/style/america-american",
        },
        {
            name: "Developing World",
            fileName: "GPSG_Mexico_DevelopingWorld_01_en",
            illustrator: "Sirako - Mexico",
            url: "/en/style/developing-world",
        },
        {
            name: "Ethnic",
            fileName: "GPSG_Zambia_Ethnic_01_en",
            illustrator: "Black Rose The Artist - Zambia",
            url: "/en/style/ethnic",
        },
        {
            name: "Victim",
            fileName: "GPSG_Nepal_Victim_01_en",
            illustrator: "Aayushma Creation - Nepal",
            url: "/en/style/victim",
        },
        {
            name: "Survivor",
            fileName: "GPSG_Nepal_Survivor_01_en",
            illustrator: "Albina Ghimire- Nepal",
            url: "/en/style/survivor",
        },
        {
            name: "Street Children",
            fileName: "GPSG_Nepal_StreetChildren_01_en",
            illustrator: "Albina Ghimire- Nepal",
            url: "/en/style/street-children", //ninos-de-la-calle
        },
        {
            name: "Foreign",
            fileName: "GPSG_Nepal_Foreign_01_en",
            illustrator: "Nilima Nayak- Nepal",
            url: "/en/style/survivor",
        },
    ],
    es: [
        {
            name: "Acentos",
            fileName: "GPSG_Mexico_AccentMark_es",
            illustrator: "Sirako - México",
            url: "/es/style/acentos",
        },
        {
            name: "Estatus económico",
            fileName: "GPSG_Argentina_DevelopingWorld_01_es",
            illustrator: "Martina Ardissono - Argentina",
            url: "/es/style/estatus-economico",
        },
        {
            name: "Discapacidades",
            fileName: "GPSG_Argentina_Disabilities_01_es",
            illustrator: "Emilie Maino - Argentina",
            url: "/es/style/discapacidades",
        },
        {
            name: "Preso(a)",
            fileName: "GPSG_Argentina_Inmate_01_es",
            illustrator: "Emilie Maino - Argentina",
            url: "/es/style/preso",
        },
        {
            name: "América",
            fileName: "GPSG_Mexico_America_01_es",
            illustrator: "Sirako - México",
            url: "/es/style/america",
        },
        {
            name: "América",
            fileName: "GPSG_Mexico_America_02_es",
            illustrator: "Chepe Daniel - México",
            url: "/es/style/america",
        },
        {
            name: "Estatus económico",
            fileName: "GPSG_Mexico_DevelopingWorld_01_es",
            illustrator: "Sirako - México",
            url: "/es/style/estatus-economico",
        },
        {
            name: "Étnico(a)",
            fileName: "GPSG_Zambia_Ethnic_01_es",
            illustrator: "Black Rose The Artist - Zambia",
            url: "/es/style/etnico",
        },
        {
            name: "Victima",
            fileName: "GPSG_Nepal_Victim_01_en",
            illustrator: "Aayushma Creation - Nepal",
            url: "/es/style/victima",
        },
        {
            name: "Sobreviviente",
            fileName: "GPSG_Nepal_Survivor_01_en",
            illustrator: "Albina Ghimire- Nepal",
            url: "/es/style/sobreviviente",
        },
        {
            name: "niños(as) de la calle",
            fileName: "GPSG_Nepal_StreetChildren_01_en",
            illustrator: "Albina Ghimire- Nepal",
            url: "/es/style/ninos-de-la-calle", //ninos-de-la-calle
        },
        {
            name: "extranjero(a)",
            fileName: "GPSG_Nepal_Foreign_01_en",
            illustrator: "Nilima Nayak- Nepal",
            url: "/es/style/extranjero",
        },
    ],
    fr: [
        {
            name: "accents",
            fileName: "GPSG_Mexico_AccentMark_en",
            illustrator: "Sirako - Mexique",
            url: "/fr/style/accents",
        },
        {
            name: "statut économique",
            fileName: "GPSG_Argentina_DevelopingWorld_01_en",
            illustrator: "Martina Ardissono - Argentine",
            url: "/fr/style/statut-économique",
        },
        {
            name: "handicaps",
            fileName: "GPSG_Argentina_Disabilities_01_en",
            illustrator: "Emilie Maino - Argentine",
            url: "/fr/style/handicaps",
        },
        {
            name: "détenu",
            fileName: "GPSG_Argentina_Inmate_01_en",
            illustrator: "Emilie Maino - Argentine",
            url: "/fr/style/détenu"
        },
        {
            name: "amérique",
            fileName: "GPSG_Mexico_America_01_en",
            illustrator: "Sirako - Mexique",
            url: "/fr/style/amérique",
        },
        {
            name: "pays en développement",
            fileName: "GPSG_Mexico_DevelopingWorld_01_en",
            illustrator: "Sirako - Mexique",
            url: "/fr/style/pays-en-développement",
        },
        {
            name: "ethnique",
            fileName: "GPSG_Zambia_Ethnic_01_en",
            illustrator: "Black Rose The Artist - Zambie",
            url: "/fr/style/ethnique",
        },
        {
            name: "victime",
            fileName: "GPSG_Nepal_Victim_01_en",
            illustrator: "Aayushma Creation - Népal",
            url: "/fr/style/victime",
        },
        {
            name: "survivant(e)",
            fileName: "GPSG_Nepal_Survivor_01_en",
            illustrator: "Albina Ghimire- Népal",
            url: "/fr/style/survivant",
        },
        {
            name: "enfants des rues",
            fileName: "GPSG_Nepal_StreetChildren_01_en",
            illustrator: "Albina Ghimire- Népal",
            url: "/fr/style/enfants-des-rues",
        },
        {
            name: "étranger",
            fileName: "GPSG_Nepal_Foreign_01_en",
            illustrator: "Nilima Nayak- Népal",
            url: "/fr/style/étranger",
        },
    ],
}
);
// select an image at random for each language
const randomImage = ref(images.value[locale.value][Math.floor(Math.random() * images.value[locale.value].length)]);
// const randomImage = ref(images.value[locale.value][0]);
// var baseUrl = window.location.origin
useAppStore();

const changeLanguage = async (lang) => {
    loading.value = true;
    updateLanguage(lang);
    locale.value = lang;
    await router.push('/')
    window.location.reload()
}
</script>

<style scoped>
.sg-app-wrap {
    font-family: 'Montserrat', sans-serif;
    background-color: rgb(253, 253, 253);
}
</style>