<template>
    <div>
        Nothing found
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
</style>